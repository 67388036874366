<template>
  <section class="not-found-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 not-found">
          <figure class="not-found__img">
            <img src="@/assets/images/not-found-img.svg" alt="" />
          </figure>

          <h2 class="not-found__title">Oops... This page was not found.</h2>

          <p class="not-found__text">
            This page does not seem to exist. Don't feel bad, let us help you
            get back on your way!
          </p>

          <AppButton
            title="Back to Homepage"
            className="primary"
            iconName="HomeIcon"
            tag="router-link"
            to="/"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
  components: {},

  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
section.not-found-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    min-height: calc(100vh - 306px);
  }
}

.not-found {
  text-align: center;

  &__img {
    margin-bottom: 58px;

    img {
      display: inline-flex;
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  &__title {
    margin-bottom: 16px;
  }

  &__text {
    color: $light-gray;
    font-size: 14px;
    max-width: 440px;
    margin: 0 auto 40px auto;
  }
}
</style>
